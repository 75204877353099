import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import ArrowIcon from '../../static/images/icons/arrow1.svg';
import PhoneIcon from '../../static/images/icons/phone.svg';
import DiscordIcon from '../../static/images/social/discord.svg';
import EmailIcon from '../../static/images/icons/email.svg';
import FacebookIcon from '../../static/images/social/facebook.svg';
import GithubIcon from '../../static/images/social/github.svg';
import InstagramIcon from '../../static/images/social/instagram.svg';
import TwitterIcon from '../../static/images/social/twitter.svg';

const iconMap = {
  Github: GithubIcon,
  Twitter: TwitterIcon,
  Facebook: FacebookIcon,
  Email: EmailIcon,
  Instagram: InstagramIcon,
  Discord: DiscordIcon,
  Phone: PhoneIcon
};

const Social = props => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      allSocialJson {
        edges {
          node {
            name
            image
            link
          }
        }
      }
      contactJson {
        email
        phone
      }
    }
  `);

  return (
    <div className="social">
      <div className="social-sub-container">
        {/* <a key="phone">
          <span className="align-items-center d-flex justify-content-center normal with-label">
            <PhoneIcon className="icon"></PhoneIcon>
            <span>Phone:</span>
            {data.contactJson.phone}
          </span>
        </a> */}
        <a key="email" target="_parent" href={`mailto:${data.contactJson.email}`}>
          <span className="align-items-center d-flex justify-content-center normal with-label">
            <EmailIcon className="icon"></EmailIcon>
            <span className="d-flex align-items-center" style={{height: '100%', lineHeight: '100%'}}>Email: {data.contactJson.email}</span>
            <ArrowIcon className="icon trans"></ArrowIcon>
          </span>
        </a>
        {/*<div className="split"></div>*/}
      </div>
      {/*<div className="social-sub-container">*/}
      {/*  {data.allSocialJson.edges.map(({ node }) => {*/}
      {/*    const Icon = iconMap[node.name];*/}
      {/*    return (*/}
      {/*      <a className="normal" key={node.name} href={node.link} target="blank">*/}
      {/*        <span className="align-content-center align-items-center d-flex justify-content-center normal">*/}
      {/*          <Icon className="icon"></Icon>*/}
      {/*        </span>*/}
      {/*      </a>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  );
};

export default Social;
