import React from 'react';
import '../scss/style.scss';
import Header from './Header';
import SEO from './SEO';
import SubFooter from './SubFooter';
import TopBtn from './TopBtn';

const Layout = props => (
  <>
    <SEO />
    <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
      <div id="wrapper" className="wrapper">
        <Header isHome={props.isHome} />
        {props.children}
        <TopBtn></TopBtn>
        <SubFooter />
      </div>
    </div>
  </>
);

export default Layout;
