import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const Link = ({ to, ...other }) => {
  //home链接 必须完全匹配
  const partiallyActive = to !== '/';
  return <GatsbyLink to={to} {...other} partiallyActive={partiallyActive}></GatsbyLink>;
};

const Menu = props => {
  const data = useStaticQuery(graphql`
    query MainMenuQuery {
      allMainMenuJson {
        edges {
          node {
            name
            url
            weight
          }
        }
      }
    }
  `);
  return (
    <div id="main-menu" className="main-menu">
      <ul>
        {data.allMainMenuJson.edges.map(({ node }) => (
          <li key={node.name}>
            <Link to={node.url} activeClassName="active">
              <span className="node-name">{node.name}</span>
              <div className="indicator">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
