import React, { useEffect, useState } from 'react';
import Icon from '../../static/images/icons/arrow1.svg';

const TopBtn = props => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const onScroll = _e => {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      setVisible(scrollTop > 100);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  if (!visible) return <></>;
  return (
    <div
      className="top-btn"
      onClick={() => {
        document.documentElement.scrollTop = 0; // For modern browsers
        document.body.scrollTop = 0;
      }}
    >
      <Icon className="icon"></Icon>
      <span className="text">Top</span>
    </div>
  );
};

export default TopBtn;
