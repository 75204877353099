import React from 'react';
import MenuIcon from '../../static/images/icons/menu.svg';
import MenuCloseIcon from '../../static/images/icons/menu-close.svg';

class Hamburger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerActive: false
    };
  }

  handleToggle = () => {
    this.setState(prevState => ({
      hamburgerActive: !prevState.hamburgerActive
    }));
    this.props.toggleMenu(this.state.hamburgerActive);
  };

  render() {
    return (
      <button id="toggle-main-menu-mobile" className={`hamburger hamburger--slider ${this.state.hamburgerActive ? 'is-active' : ''}`} type="button" onClick={this.handleToggle}>
        {this.state.hamburgerActive ? <MenuCloseIcon></MenuCloseIcon> : <MenuIcon style={{ width: 20, height: 20 }}></MenuIcon>}
      </button>
    );
  }
}

export default Hamburger;
